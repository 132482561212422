import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCancelApi } from "../../redux/authslice";
import Header from "../../layout/component/header";
import Header2 from "../../layout/component/header2";
import Footer2 from "../../layout/component/footer2";

export const CancelCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSuccess, setisSuccess] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const bak = searchParams.get("bak");
  const encodedString = bak;
  const decodedString = atob(encodedString);
  const data = decodedString;

  const successRef = useRef(null);
  successRef.current = isSuccess;

  useEffect(() => {
    if (!successRef.current) {
      dispatch(getCancelApi({ data, navigate }));
      setTimeout(() => {}, 3000);
      setisSuccess(true);
    }
  }, []);

  return (
    <>
      <Header2 />
      <div class="container mt-5 mb-8">
        <div class="row justify-content-center mb">
          <div class="col-12 col-md-6">
            <div class="card shadow-lg">
              <div class="card-body text-center">
                <div
                  class="rounded-circle bg-light d-flex align-items-center justify-content-center mx-auto"
                  style={{ height: "8rem", width: "8rem" }}
                >
                  <i class="text-danger display-1">✘</i>
                </div>
                <h1 class="mt-4 text-danger">Cancelled</h1>
                <p class="text-muted">
                  Your payment has been cancelled.
                  <br />
                  If you have any questions, please contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};
