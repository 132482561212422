import React from 'react'
import logo from '../../images/logo.png'

const Header2 = () => {
  return (
    <nav className={window.location.pathname === "/create-blog" ? "navbar navbar-expand-lg navbar-light bg-gradient-light z-index-3 py-3 draggable" : "navbar navbar-expand-lg navbar-dark bg-gradient-dark z-index-3 py-3 draggable"} draggable="true">
      <div className="container">
        <img src={logo} alt='' />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        {window.location.pathname === "/create-blog" ? "" : <a href="#" className="btn btn-primary ms-3 contact-us">
          CONTACT US
        </a>}

      </div>
    </nav>
  )
}

export default Header2