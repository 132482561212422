import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RegisterUser } from "../../redux/authslice";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { priceId } = location.state || {};
  const blog_id = localStorage.getItem("blog_post_id")
  const [showPassword, setShowPassword] = useState(false);
  const [signupData, setSignupData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    price_id: priceId,
    blog_id: blog_id,
    code: "",
  });

  const [errors, setErrors] = useState({});

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validate = () => {
    const errors = {};

    if (!signupData?.first_name.trim()) {
      errors.first_name = "First name required";
    }
    if (!signupData?.last_name.trim()) {
      errors.last_name = "Last name required";
    }
    if (!signupData?.username.trim()) {
      errors.username = "Username required";
    }
    if (!signupData?.email.trim()) {
      errors.email = "Email address required";
    } else if (!/\S+@\S+\.\S+/.test(signupData?.email)) {
      errors.email = "Email address invalid";
    }
    if (!signupData?.password.trim()) {
      errors.password = "Password required";
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(signupData?.password)) {
      errors.password =
        "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors)?.length === 0) {
      dispatch(RegisterUser({ signupData, setSignupData, dispatch, navigate }));
      return;
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="form-section align-self-center">
      <h1>Welcome!</h1>
      <h3>Sign Up for Your Account</h3>
      <div className="clearfix" />
      <form onSubmit={handleSubmit}>
        {/* Row for first name and last name */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <label htmlFor="first_field" className="form-label">
              First name
            </label>
            <input
              name="first_name"
              type="text"
              className="form-control"
              id="first_field"
              placeholder="First Name"
              aria-label="First Name"
              value={signupData?.first_name}
              onChange={handleChange}
            />
            {errors.first_name && <p className="error_text">{errors.first_name}</p>}
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="last_field" className="form-label">
              Last name
            </label>
            <input
              name="last_name"
              type="text"
              className="form-control"
              id="last_field"
              placeholder="Last Name"
              aria-label="Last Name"
              value={signupData?.last_name}
              onChange={handleChange}
            />
            {errors.last_name && <p className="error_text">{errors.last_name}</p>}
          </div>
        </div>

        {/* Row for username and email */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <label htmlFor="username_field" className="form-label">
              Username
            </label>
            <input
              name="username"
              type="text"
              className="form-control"
              id="username_field"
              placeholder="User Name"
              aria-label="User Name"
              value={signupData?.username}
              onChange={handleChange}
            />
            {errors.username && <p className="error_text">{errors.username}</p>}
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="email_field" className="form-label">
              Email address
            </label>
            <input
              name="email"
              type="email"
              className="form-control"
              id="email_field"
              placeholder="Email Address"
              aria-label="Email Address"
              value={signupData?.email}
              onChange={handleChange}
            />
            {errors.email && <p className="error_text">{errors.email}</p>}
          </div>
        </div>

        {/* Password field */}
        <div className="form-group form-box">
          <label htmlFor="password_field" className="form-label">
            Password
          </label>
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            className="form-control"
            autoComplete="off"
            id="password_field"
            placeholder="Password"
            aria-label="Password"
            value={signupData?.password}
            onChange={handleChange}
          />
          <div className="eye-div">
            <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"} onClick={togglePasswordVisibility}></i>
          </div>
          {errors.password && <p className="error_text">{errors.password}</p>}
        </div>
        <div className="form-group form-box">
          <label htmlFor="password_field" className="form-label">
            Referral code (Optional)
          </label>
          <input
            name="code"
            type="text"
            className="form-control"
            autoComplete="off"
            id="Referral_code_field"
            placeholder="Referral code"
            aria-label="Referral code"
            value={signupData?.code}
            onChange={handleChange}
          />
        </div>
        <div className="form-group clearfix">
          <button type="submit" className="btn-md btn-theme w-100">
            Register
          </button>
        </div>
      </form>
      <p>
        Already a member? <a href="/signin">Login here</a>
      </p>
    </div>
  );
};

export default Register;
