import React from "react";
import "./land.css";

import vid from "./img/video/AI_Picture.mp4";
import vid1 from "./img/video/AI_Robot_HiFive.mp4";
import vid2 from "./img/video/Hero_Banner.mp4";
import vid3 from "./img/video/Man_Standing.mp4";
import vid4 from "./img/video/Video_of_Hands.mp4";
import Carousel from "./Carousel";
import Generate from "./generate";
import Plan from "./plan";
import { LandHome } from "./landHome";
import LandPageFooter from "./landPageFooter";

const Landpage = () => {
  const videos = [vid, vid1, vid2, vid3, vid4];

  return (
    <>
      <header>
        <LandHome />
        <div className="header-bottom">
          <Carousel videos={videos} />
          <Generate />
        </div>
      </header>
      <Plan />
      <LandPageFooter />
    </>
  );
};

export default Landpage;
