import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_reff_code } from '../../redux/authslice';

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ReffData } = useSelector((state) => state.counter);
    const [copyMessage, setCopyMessage] = useState('');
    const role = localStorage.getItem("role");

    const logout = () => {
        localStorage.clear();
        navigate("/signin");
    };

    const handleCopyReferralCode = () => {
        if (ReffData && ReffData[0].code && ReffData[0].base_url) {
            const textToCopy = `Link: ${ReffData[0].base_url}\n\nReferral Code: ${ReffData[0].code}`;
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setCopyMessage('Referral information copied!');
                    setTimeout(() => setCopyMessage(''), 2000);
                })
                .catch((error) => {
                    console.error('Failed to copy referral information:', error);
                });
        }
    };

    useEffect(() => {
        if (role) {
            dispatch(get_reff_code(dispatch));
        }
    }, [dispatch]);

    return (
        <>
            <section className="dashboard-all top-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-flex">
                                <ul>
                                    <li>
                                        <button className="tog-bt">
                                            <i className="fa-solid fa-bars" />
                                            <i className="fa-solid fa-caret-left" />
                                        </button>
                                    </li>
                                    {ReffData?.length == 0 && role ? "" :
                                        <li>
                                            <button className="yyuyu" onClick={handleCopyReferralCode}>Click here for Referral code</button>
                                            {copyMessage && (
                                                <span style={{
                                                    color: 'green',
                                                    marginLeft: '10px',
                                                    fontSize: '14px',
                                                }}>
                                                    {copyMessage}
                                                </span>
                                            )}
                                        </li>
                                    }
                                    <li>
                                        <button className="log-out-bt" onClick={logout}>Logout</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Header