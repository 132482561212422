import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetpaasword } from '../../redux/authslice';

const Reset = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [signupData, setSignupData] = useState({
        email: "",
        password: "",
        new_password: "",
        confirm_password: "",
        otp: ""
    });

    const [errors, setErrors] = useState({});

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const validate = () => {
        const errors = {};

        if (!signupData?.email.trim()) {
            errors.email = "Email address required";
        } else if (!/\S+@\S+\.\S+/.test(signupData?.email)) {
            errors.email = "Email address  invalid";
        }

        if (!signupData?.password.trim()) {
            errors.password = "Password required";
        } else if (
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                signupData?.password
            )
        ) {
            errors.password =
                "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character";
        }

        if (!signupData?.confirm_password.trim()) {
            errors.confirm_password = "Confirm Password is required";
        } else if (signupData?.password !== signupData?.confirm_password) {
            errors.confirm_password = "Passwords do not match";
        }

        if (!signupData?.otp.trim()) {
            errors.otp = "OTP is required";
        }

        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSignupData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors)?.length === 0) {
            dispatch(resetpaasword({ signupData, setSignupData, dispatch, navigate }));
            return;
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <div className="form-section align-self-center">
            <h3>Reset your password</h3>
            <div className="clearfix" />
            <form action="#" method="GET" onSubmit={handleSubmit}>

                <div className="form-group form-box">
                    <label htmlFor="email_field" className="form-label">
                        Email address
                    </label>
                    <input
                        name="email"
                        type="text"
                        className="form-control"
                        id="email_field"
                        placeholder="Email Address"
                        aria-label="Email Address"
                        value={signupData?.email}
                        onChange={handleChange}
                    />
                    {errors.email && (
                        <p className="error_text">{errors.email}</p>
                    )}
                </div>

                <div className="form-group form-box">
                    <label htmlFor="otp_field" className="form-label">
                        Enter OTP
                    </label>
                    <input
                        name="otp"
                        type="text"
                        className="form-control"
                        id="otp_field"
                        placeholder="Enter OTP"
                        aria-label="OTP"
                        value={signupData?.otp}
                        onChange={handleChange}
                    />
                    {errors.otp && (
                        <p className="error_text">{errors.otp}</p>
                    )}
                </div>

                <div className="form-group form-box">
                    <label htmlFor="password_field" className="form-label">
                        Password
                    </label>
                    <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        autoComplete="off"
                        id="password_field"
                        placeholder="Password"
                        aria-label="Password"
                        value={signupData?.password}
                        onChange={handleChange}
                    />
                    <div className="eye-div">
                        <i
                            className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}
                            onClick={togglePasswordVisibility}
                        ></i>
                    </div>
                    {errors.password && (
                        <p className="error_text">{errors.password}</p>
                    )}
                </div>

                <div className="form-group form-box">
                    <label htmlFor="confirm_password_field" className="form-label">
                        Confirm Password
                    </label>
                    <input
                        name="confirm_password"
                        type={showPassword1 ? "text" : "password"}
                        className="form-control"
                        id="confirm_password_field"
                        placeholder="Confirm Password"
                        aria-label="Confirm Password"
                        value={signupData?.confirm_password}
                        onChange={handleChange}
                    />
                    <div className="eye-div">
                        <i
                            className={showPassword1 ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}
                            onClick={togglePasswordVisibility1}
                        ></i>
                    </div>
                    {errors.confirm_password && (
                        <p className="error_text">{errors.confirm_password}</p>
                    )}
                </div>

                <div className="form-group clearfix">
                    <button type="submit" className="btn-md btn-theme w-100">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Reset