import React, { useEffect } from 'react';
import Header from './component/header';
import Sidebar from './component/sidebar';
import Footer from './component/footer';
import { useNavigate } from 'react-router-dom';

const Layout = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");

        if (!token) {
            navigate("/signin")
        }

    }, [])
    return (
        <>
            <Header />
            <Sidebar />
            {children}
            <Footer />
        </>
    )
}

export default Layout