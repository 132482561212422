import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogSubscriptions } from "../../redux/authslice";
import { useNavigate } from "react-router-dom";
import "./home.css";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllBlogs } = useSelector((state) => state.counter);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    dispatch(getBlogSubscriptions(dispatch));
  }, []);

  const cleanContent = (content) => {
    return typeof content === "string"
      ? content
        .replace(/\*\*/g, "")
        .replace(/\*/g, "")
        .replace(/:/g, "")
        .replace(/title/gi, "")
        .split(" ")
        .slice(2)
        .join(" ")
      : "";
  };

  const handleView = (item) => {
    const id = item?.blog;
    if (id !== null) {
      localStorage.setItem("blog_post_id", id);
      navigate("/blog-page");
    }
  };

  const filteredBlogs = getAllBlogs.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredBlogs.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedBlogs = filteredBlogs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const generatePagination = () => {
    const pages = [];

    if (totalPages <= 5) {
      // Show all pages if there are 5 or fewer
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Always include first and last page
      pages.push(1);

      if (currentPage > 3) {
        pages.push("..."); // Ellipsis before current range
      }

      // Add current page and its neighbors
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 2) {
        pages.push("..."); // Ellipsis after current range
      }

      pages.push(totalPages);
    }

    return pages;
  };


  return (
    <section className="dasboard-all pages">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="content-dasboard">
              <div className="search-input">
                <ul className="input-ul d-flex justify-content-between">
                  <li>
                    <input
                      className="same-input"
                      placeholder="Search"
                      type="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    &nbsp;
                    &nbsp;
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </li>
                  <li>
                    <select
                      className="same-input"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value={5}>5 </option>
                      <option value={10}>10 </option>
                      <option value={15}>15 </option>
                    </select>
                  </li>
                </ul>
              </div>
              <div className="last-table">
                <table>
                  <thead>
                    <tr>
                      <th>Blog Id</th>
                      <th>Blog Image</th>
                      <th>Blog Title</th>
                      <th>Blog Content</th>
                      <th>View blog</th>
                      <th>Edit blog</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedBlogs.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item?.id}</td>
                        <td>
                          <img src={item?.image_url} width="80" alt="blog-img" />
                        </td>
                        <td>{item?.title.slice(0, 21)}...</td>
                        <td>
                          {cleanContent(item?.data[0]?.content).slice(0, 50)}...
                        </td>
                        <td
                          onClick={() => navigate("/main-blog")}
                          style={{ cursor: "pointer" }}
                        >
                          View
                        </td>
                        <td
                          onClick={() => handleView(item)}
                          style={{ cursor: "pointer" }}
                        >
                          Edit
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination Controls */}
                <div className="pagination">
                  {generatePagination().map((page, idx) => (
                    typeof page === "number" ? (
                      <button
                        key={idx}
                        className={`page-button ${currentPage === page ? "active" : ""}`}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    ) : (
                      <span key={idx} className="spnspc">...</span>
                    )
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
