import React from 'react'
import { useNavigate } from 'react-router-dom'

const Common = ({ children }) => {
    const navigate = useNavigate();
    return (
        <div className="login-24">
            <div className="login-24-inner">
                <div className="container">
                    <div className="row login-box">
                        <div className="col-lg-6 bg-color-15 pad-0 none-992 bg-img" />
                        <div className="col-lg-6 pad-0 form-info">
                            <div className="d-flex justify-content-end me-5 mt-3" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                                <i className="fa-solid fa-arrow-right"></i>
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Common