import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActive, adminuserlist } from "../../redux/authslice";

const AdminHome = () => {
  const dispatch = useDispatch();
  const { userlist } = useSelector((state) => state.counter);

  const activeuser = userlist?.users?.filter((ele) => ele.active === true) || [];
  const plan = userlist?.users?.filter((ele) => ele.plan) || [];
  const userStats = [
    { label: "Active users", count: activeuser?.length },
    { label: "Total users", count: userlist?.total_users || 0 },
    { label: "Subscribed users", count: plan?.length || 0 },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = userlist?.users?.slice(indexOfFirstUser, indexOfLastUser) || [];
  const totalPages = Math.ceil((userlist?.users?.length || 0) / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    dispatch(adminuserlist(dispatch));
  }, [dispatch]);


  const handleSwitchChange = (user) => {
    dispatch(UserActive(user, dispatch));
  };

  return (
    <section className="dasboard-all pages">
      <div className="container-fluid">
        <h1 className="fs-4 fw-bold text-dark">Dashboard</h1>
        <h2 className="fs-5 fw-bold pt-4 text-dark">Overview</h2>
        <div className="row g-3 py-4">
          {userStats?.map((item, index) => (
            <div key={index} className="col-lg-4">
              <div className="bg-light rounded p-3">
                <p className="text-dark">{item?.label}</p>
                <p className="fs-4 fw-bold">{item?.count}</p>
              </div>
            </div>
          ))}
        </div>

        <h2 className="fs-5 fw-bold pt-4 text-dark">Users</h2>
        <div className="table-responsive">
          <table className="table table-bordered mt-3">
            <thead className="table-light">
              <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Username</th>
                <th>Active Status</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => (
                <tr key={index}>
                  <td>{user?.first_name}</td>
                  <td>{user?.last_name}</td>
                  <td>{user?.email}</td>
                  <td>{user?.username}</td>
                  <td>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input custom-switch"
                        type="checkbox"
                        role="switch"
                        checked={user?.active}
                        onChange={() => handleSwitchChange(user)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>
            <label className="me-2">Rows per page: </label>
            <select
              className="form-select form-select-sm d-inline-block"
              style={{ width: "auto" }}
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}

            >
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>

          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-outline-primary rounded-circle"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ width: "35px", height: "35px" }}
            >
              {"<"}
            </button>
            <span className="mx-3 fw-bold text-primary" style={{ fontSize: "1rem" }}>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-sm btn-outline-primary rounded-circle"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              style={{ width: "35px", height: "35px" }}
            >
              {">"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminHome;
