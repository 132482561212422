import React from "react";
import "./land.css";
import logo from "./img/logo.png";

export const LandHome = () => {
  return (
    <header>
      <div className="header-top">
        <div className="container">
          <div className="header-wrap">
            <div className="header-btn text-end">
              <ul className="list-unstyled d-flex d-inline-flex">
                <li>
                  <a href="/create-blog">create your blog</a>
                </li>
                <li>
                  <a href="/signup">SIGN UP NOW FOR FREE</a>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 d-none d-lg-block d-md-block d-none d-sm-none">
                <div className="header-logo">
                  <a href="index-2.html">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </a>
                </div>
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="mainmenu">
                  <nav className="navbar navbar-expand-md navbar-dark">
                    {/* Brand */}
                    <a className="navbar-brand d-block d-md-none d-lg-none d-sm-block" href="index-2.html">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </a>
                    {/* Toggler/collapsibe Button */}
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsibleNavbar"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />{" "}
                    </button>
                    {/* Navbar links */}
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <a className="active" href="index-2.html">
                            HOME
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#">About</a>
                        </li>
                        <li className="nav-item">
                          <a href="#">Benefits</a>
                        </li>
                        <li className="nav-item">
                          <a href="#">CONTACT US</a>
                        </li>
                        <li className="nav-item">
                          <a href="/create-blog">create your blog</a>
                        </li>
                        <li className="nav-item">
                          <a href="/signup">SIGN UP NOW FOR FREE</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
