import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { LoginUser } from '../../redux/authslice';

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [signinData, setSigninData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
    });

    const [errors, setErrors] = useState({});

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validate = () => {
        const errors = {};

        if (!signinData?.email.trim()) {
            errors.email = "Email or Username required";
        }
        // if (!signinData?.password.trim()) {
        //     errors.password = "Password required";
        // } else if (
        //     !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        //         signinData?.password
        //     )
        // ) {
        //     errors.password =
        //         "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character";
        // }

        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSigninData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors)?.length === 0) {
            dispatch(LoginUser({ dispatch, navigate, signinData, setSigninData }))
            return
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            <div className="form-section align-self-center">
                <h1>Welcome!</h1>
                <h3>Sign Into Your Account</h3>
                <div className="clearfix" />
                <form action="#" method="GET" onSubmit={handleSubmit}>
                    <div className="form-group form-box">
                        <label htmlFor="first_field" className="form-label">
                            Email or Username
                        </label>
                        <input
                            name="email"
                            type="text"
                            className="form-control"
                            id="first_field"
                            placeholder="Enter email or username "
                            aria-label="Email Address"
                            value={signinData?.email}
                            onChange={handleChange}
                        />
                        {errors.email && (
                            <p className="error_text">{errors.email}</p>
                        )}
                    </div>
                    <div className="form-group form-box">
                        <label htmlFor="second_field" className="form-label">
                            Password
                        </label>
                        <input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            autoComplete="off"
                            id="second_field"
                            placeholder="Password"
                            aria-label="Password"
                            value={signinData?.password}
                            onChange={handleChange}
                        />
                        <div className='eye-div'>

                            <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"} onClick={togglePasswordVisibility}
                            ></i>
                        </div>
                        {errors.password && (
                            <p className="error_text">{errors.password}</p>
                        )}
                    </div>
                    <div className="checkbox form-group form-box">
                        {/* <div className="form-check checkbox-theme">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="rememberMe"
                            />
                            <label className="form-check-label" htmlFor="rememberMe">
                                Remember me
                            </label>
                        </div> */}
                        <a href="/forgot" className="forgot-password">
                            Forgot Password
                        </a>
                    </div>
                    <div className="form-group clearfix">
                        <button type="submit" className="btn-md btn-theme w-100">
                            Login
                        </button>
                    </div>
                </form>
                <p>
                    Don't have an account? <a href="/signup">Register here</a>
                </p>
                {/* <div className="social-list">
                    <a href="#">
                        <i className="fa fa-facebook" />
                    </a>
                    <a href="#">
                        <i className="fa fa-twitter" />
                    </a>
                    <a href="#">
                        <i className="fa fa-google" />
                    </a>
                    <a href="#">
                        <i className="fa fa-linkedin" />
                    </a>
                    <a href="#">
                        <i className="fa fa-pinterest" />
                    </a>
                    <a href="#">
                        <i className="fa fa-youtube" />
                    </a>
                </div> */}
            </div>

        </>
    )
}

export default Login