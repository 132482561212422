import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "./manual.css";
import { edit_manual_blog, get_mannual_blog } from "../../redux/authslice";
import ButtonCustomizer from "../../layout/component/floatbutton";
import VerticalCarousel from "../../layout/component/VerticalCarousel";


const MannualBlogPage = () => {
  const dispatch = useDispatch();
  const { manualBlogData } = useSelector((state) => state.counter);
  const navigate = useNavigate();
  const tokenn = localStorage.getItem("token");

  const posts = [manualBlogData];

  console.log("posts", posts)


  useEffect(() => {
    const mannual_blog_post_id = localStorage.getItem("manual_blog_post_id");
    if (!mannual_blog_post_id) {
      navigate("/");
    }
    dispatch(get_mannual_blog({ dispatch, mannual_blog_post_id }));
  }, [dispatch, navigate]);



  const [selectedPost, setSelectedPost] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [Title, setTitle] = useState("");
  const [Content, setContent] = useState("");
  const planSectionRef = useRef(null);


  const handleSlide = () => {
    planSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const openModal = (post) => {
    setSelectedPost(post);
    setTitle(post?.title);
    setContent(post?.description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPost(null);
    setIsEditing(false);
  };

  const handleEdit = () => setIsEditing(true);

  const handleSave = () => {
    if (Title !== selectedPost.title || Content !== selectedPost.description) {
      const payload = { Title, Content, id: selectedPost?.id };
      dispatch(edit_manual_blog({ payload, dispatch }));
      setSelectedPost(null);
    } else {
      toast.info("No changes made to the blog post.");
    }
    setIsModalOpen(false);
    setIsEditing(false);
  };

  const handleNavigate = () => navigate("/", { state: { scrollToPlan: true } });

  const cleanContent = (content) => {
    return content
      ?.replace(/\*\*/g, "")
      ?.replace(/\*/g, "")
      ?.replace(/:/g, "")
      ?.replace(/###/g, "\n\n\n")
      ?.split("\n")
      ?.map((line) => line.trim())
      ?.filter((line) => line.length > 0)
      ?.join("\n");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark z-index-3 py-3 draggable"
        style={{
          background: manualBlogData?.color_scheme || 'linear-gradient(310deg,#141727,#3a416f)',
        }} draggable="true">
        <div className="container">
          <Link className="navbar-brand" to="/dashboard">
            {manualBlogData?.title?.toUpperCase()}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <a href="#" className="btn btn-primary ms-3 contact-us">
            CONTACT US
          </a>
        </div>
      </nav>

      {/* -------------Main Section Start--------------- */}
      <section
        className="hero-section text-center text-white"
        style={{
          backgroundImage: `url(${manualBlogData?.business_info?.image_logo}`,
          backgroundSize: "cover",
          padding: "100px 0",
        }}
      >
        <span className="mask bg-gradient-dark opacity-7" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-9 text-start" style={{ position: "relative" }}>
              <h1
                className="display-2 font-weight-bolder text-white mb-4"
                style={{ fontWeight: 700, fontSize: manualBlogData?.title?.length > 40 ? 46 : 56 }}
              >
                A Blog for {manualBlogData?.title} Lovers
              </h1>
              <p className="lead text-white mb-5" style={{ fontWeight: 400 }}>
                {cleanContent(manualBlogData?.description)?.slice(0, 193)}
              </p>
              <a
                className="btn btn-white"
                onClick={handleSlide}
                style={{ backgroundColor: "#fff", color: "#000" }}
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* -------------Second Section Start--------------- */}
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col text-center mb-4">
              <h2 className="" style={{ fontWeight: 400, color: "#344767" }}>
                Latest Blogs
              </h2>
              <p style={{ color: "#67748e", fontWeight: 400 }}>
                See what people from all around the world are saying about our website.
              </p>
            </div>
          </div>
          <div className="container" style={{ maxWidth: "1200px", margin: "0 auto", paddingTop: "20px", paddingBottom: "20px" }}>
            <div className="outer-div">
              <div className="post repeat-div">
                <div className="left-90" ref={planSectionRef}>
                  <img
                    src={manualBlogData?.business_info?.image_logo}
                    alt={manualBlogData?.title}
                    style={{ width: "100%", borderRadius: "8px", marginBottom: "10px" }}
                  />
                </div>
                <div className="flex-right-90">
                  <h2 className="h2-1">{manualBlogData?.title}</h2>
                  <p className="p-cus">{cleanContent(manualBlogData?.description)?.slice(0, 700)}...</p>
                  <a
                    className="a-tag"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openModal(manualBlogData);
                    }}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>

            {isModalOpen && (
              <div style={modalStyle} className="popup-main">
                <div className="popup-main-1" style={modalContentStyle}>
                  <span style={closeButtonStyle} onClick={closeModal}>
                    ×
                  </span>
                  {isEditing ? (
                    <div className="edit-input">
                      <input
                        type="text"
                        value={Title}
                        className="edit-inner-input"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <textarea
                        value={cleanContent(Content)}
                        className="edit-textarea-input"
                        onChange={(e) => setContent(e.target.value)}
                      />
                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                        <button style={editButtonStyle} onClick={handleSave}>
                          Save
                        </button>
                        <button style={closeButtonStyleModal} onClick={closeModal}>
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h2 style={{ color: "#333" }}>{selectedPost?.title}</h2>
                      <img
                        src={selectedPost?.business_info?.image_logo}
                        alt={selectedPost?.title}
                        style={{ borderRadius: "8px" }}
                      />
                      <p style={{ color: "#555" }}>{cleanContent(selectedPost?.description)}</p>
                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                        <button style={editButtonStyle} onClick={handleEdit}>
                          Edit Post
                        </button>
                        <button style={closeButtonStyleModal} onClick={closeModal}>
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {tokenn !== null || undefined ? (
              <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <Link to="/all-blogs" onClick={() => localStorage.removeItem("blog_post_id")}>
                  <button style={{ background: "#344767" }} onClick={() => {
                    localStorage.removeItem('manual_blog_post_id');
                  }}>Save This Blog</button>
                </Link>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <button style={{ background: "#344767" }} onClick={handleNavigate}>
                  Sign Up for Account to Keep this Blog Forever
                </button>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* -------------Subscribe Section Start--------------- */}
      <section>
        <div className="py-5 draggable" draggable="true">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-start">
                <h4 style={{ color: "#344767", fontWeight: 700 }}>Get Update with the Newest Blogs</h4>
                <p className="mb-0" style={{ color: "#67748e", fontWeight: 400 }}>
                  The latest news, articles and resources sent to your inbox weekly.
                </p>
              </div>
              <div className="col-lg-5 ms-auto text-end mt-4 mt-lg-0">
                <div className="row">
                  <div className="col-lg-8 col-md-4 col-7" style={{ zIndex: 0 }}>
                    <div className="form-group mb-0">
                      <div className="input-group111">
                        <span className="input-group-text1111">
                          <i className="fa-solid fa-envelope"></i>
                        </span>
                        <input className="form-control-blog1212" placeholder="Your Email" type="email" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-5 text-start">
                    <button className="contact-us ui-bt" style={{ border: "none" }}>
                      SUBSCRIBE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <VerticalCarousel posts={posts}
          defaultHeading="Latest Blog Posts"
          initialFontSize={24}
          initialFontColor="#000000" />
        <ButtonCustomizer />
      </section>
    </>
  );
};

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "500px",
  position: "relative",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  overflowY: "auto",
  maxHeight: "80vh",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  fontSize: "24px",
  cursor: "pointer",
  color: "#333",
};

const editButtonStyle = {
  backgroundColor: "#344767",
  color: "white",
  border: "none",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};

const closeButtonStyleModal = {
  backgroundColor: "transparent",
  color: "#333",
  border: "1px solid #333",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};

export default MannualBlogPage;
