import React, { useEffect } from "react";
import { get_all_blogs } from "../redux/authslice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const BlogPostList = ({ blogs }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const main_blog_data = useSelector((state) => state?.counter?.main_blog_data);

  console.log("id", id);
  useEffect(() => {
    dispatch(get_all_blogs(dispatch));
  }, []);
  return (
    <div className="blog-posts m-5">
      {main_blog_data?.[0]?.post
        ?.filter((ele) => ele.id == id)
        ?.map((blog) => (
          <div key={blog.id} className="blog-post">
            <div className="d-flex justify-content-center">
              <img
                src={blog?.image_url}
                alt={blog?.title}
                style={{
                  width: "100%",
                  maxWidth: "70%",
                  marginBottom: "10px",
                  objectFit: "cover",
                  maxHeight: "300px",
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <h2 className="text-dark">{blog?.title}</h2>
            </div>
            <div className="d-flex justify-content-center p-5">
              <p
                className="font-jost blockquote"
                style={{ fontFamily: "jost" }}
              >
                {blog?.content}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default BlogPostList;
