import React, { useState } from "react";
import Generate from "../../../pages/landing/generate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { create_blog, create_url_blog } from "../../../redux/authslice";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUrlLayout, setIsUrlLayout] = useState(true);
  const [url, setUrl] = useState("");
  const [blogName, setBlogName] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (isUrlLayout && !url) newErrors.url = "URL is required";
    if (!isUrlLayout) {
      if (!blogName) newErrors.blogName = "Blog name is required";
      if (!blogDescription) newErrors.blogDescription = "Blog description is required";
    }
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        blogName,
        blogDescription
    }
      isUrlLayout ? dispatch(create_url_blog({ url, navigate,dispatch })) : dispatch(create_blog({ payload, navigate,dispatch }));
    }
  };
  return (
    <section className="dasboard-all pages">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 ">
            <div className="container text-center my-5">
              <h1>Automate Your Blog Posting with AI</h1>
              <p>AI-powered platform to create and post unique, SEO-optimized blog content regularly.</p>

              <form onSubmit={handleSubmit} className="my-3">
                {isUrlLayout ? (
                  <>
                    <input
                      type="text"
                      placeholder="Enter Website URL (e.g., yoursite.com)"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      className="form-control my-2"
                    />
                    {errors.url && <span className="text-danger">{errors.url}</span>}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      placeholder="Blog Name (e.g., The Dog Blog)"
                      value={blogName}
                      onChange={(e) => setBlogName(e.target.value)}
                      className="form-control my-2"
                    />
                    {errors.blogName && <span className="text-danger">{errors.blogName}</span>}
                    <input
                      type="text"
                      placeholder="Blog Description"
                      value={blogDescription}
                      onChange={(e) => setBlogDescription(e.target.value)}
                      className="form-control my-2"
                    />
                    {errors.blogDescription && <span className="text-danger">{errors.blogDescription}</span>}
                  </>
                )}
                <button type="submit" className="btn btn-primary my-3">
                  BUILD MY BLOG
                </button>
              </form>

              <span
                style={{ cursor: "pointer" }}
                className="text-primary"
                onClick={() => {
                  setIsUrlLayout(!isUrlLayout);
                  setErrors({});
                }}
              >
                {isUrlLayout ? "No website? Click here" : "Have a website? Generate from URL"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBlog;
