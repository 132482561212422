import { useRoutes, Navigate } from "react-router-dom";
import pagesData from "./pageRoute";

const Router = () => {
    const userRole = localStorage?.getItem("role");

    const routesWithRoleCheck = pagesData?.map((route) => {
        if (route.requiresAdmin && userRole !== 'admin') {
            return {
                ...route,
                element: <Navigate to="/not-authorized" />
            };
        }
        return route;
    });

    return useRoutes(routesWithRoleCheck);
};

export default Router;
