import React, { useEffect, useState } from 'react';
import { floating, get_floating } from '../../redux/authslice';
import { useDispatch, useSelector } from 'react-redux';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { toast } from 'react-toastify';

const FloatingWidgetButton = ({
    text,
    bg_color,
    text_color,
    fontSize,
    onClick,
}) => {
    const buttonStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: bg_color,
        color: text_color,
        padding: '10px 20px',
        borderRadius: '25px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Arial, sans-serif',
        fontSize: fontSize,
        cursor: 'pointer',
        textAlign: 'center',
        zIndex: '1000',
        transition: 'transform 0.2s ease',
    };

    const handleHover = (e) => {
        e.target.style.transform = e.type === 'mouseenter' ? 'scale(1.05)' : 'scale(1)';
    };

    return (
        <div
            style={buttonStyle}
            onClick={onClick}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
        >
            {text}
        </div>
    );
};

const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    toast.info("Code copied to clipboard!");
};




// Modal Component
const Modal = ({ isOpen, onClose, children, handleSubmit, htmlCode,
    cssCode }) => {
    if (!isOpen) return null;

    return (
        <>
            <div style={modalOverlayStyle}>
                <div style={modalStyle}>
                    {children}
                    <div style={{ textAlign: 'center' }}>
                        <button className='btn btn-danger' onClick={onClose} >Close</button>
                        <button className="btn btn-primary" onClick={handleSubmit} >Save</button>

                        <div
                            className="code-section"
                            style={{
                                marginTop: "40px",
                                padding: "20px",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                backgroundColor: "#f9f9f9",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <h3>HTML Code for Floating Button</h3>
                            <pre
                                style={{
                                    backgroundColor: "#f4f4f4",
                                    padding: "15px",
                                    borderRadius: "5px",
                                    overflowX: "auto",
                                }}
                            >
                                {htmlCode}
                            </pre>

                            <h3>CSS Code for Floating Button</h3>
                            <pre
                                style={{
                                    backgroundColor: "#f4f4f4",
                                    padding: "15px",
                                    borderRadius: "5px",
                                    overflowX: "auto",
                                }}
                            >
                                {cssCode}
                            </pre>

                            {/* Copy Buttons */}
                            <button
                                onClick={() => copyToClipboard(htmlCode)}
                                style={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                Copy HTML Code
                            </button>
                            <button
                                onClick={() => copyToClipboard(cssCode)}
                                style={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                Copy CSS Code
                            </button>

                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const modalStyle = {
    background: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    width: "100%",
    color: "black",
    maxWidth: "60%",
    maxHeight: "65%",
    overflow: "auto"
};


const saveButtonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
}

const ButtonCustomizer = () => {
    const dispatch = useDispatch();
    const floating_data = useSelector((state) => state?.counter?.floating_data)
    const [text, setText] = useState("Our Blog");
    const [bg_color, setbg_color] = useState("#007bff");
    const [text_color, settext_color] = useState("#ffffff");
    const [fontSize, setfontSize] = useState("16px");
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (floating_data?.length > 0) {
            setText(floating_data[0]?.text || "Our Blog");
            setbg_color(floating_data[0]?.bg_color || "#007bff");
            settext_color(floating_data[0]?.text_color || "#ffffff");
            setfontSize(floating_data[0]?.font_size || "16px");
        }
    }, [isModalOpen, floating_data]);


    console.log(text, "text", bg_color, text_color, fontSize)
    const htmlCode = `<div class="button-container" onclick="window.location.href='http://65.1.176.130:3000';">
    <div class="floating-button">
      ${text}
    </div>
    </div>`;

    const cssCode = `.button-container {
    padding: 20px;
    }
    
    .floating-button {
    position: fixed;
    bottom: 35px;
    right: 20px;
    background-color: ${bg_color};
    color: ${text_color};
    padding: 10px 20px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
    font-family: Arial, sans-serif;
    font-size: ${fontSize};
    cursor: pointer;
    text-align: center;
    z-index: 1000;
    }
    `;

    const obj = {
        "bg_color": bg_color,
        "blog_id": localStorage?.getItem("blog_post_id"),
        "font_size": fontSize,
        "text": text,
        "text_color": text_color
    }

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    const handleSave = () => {
        dispatch(floating({ dispatch, obj, setIsModalOpen }))
    }

    useEffect(() => {
        const blog_id = localStorage.getItem("blog_post_id")
        dispatch(get_floating({ dispatch, blog_id }))
    }, [])

    return (
        <div style={{ padding: '20px' }}>
            {/* <h2>Customize Floating Button</h2> */}
            <FloatingWidgetButton
                text={text}
                bg_color={bg_color}
                text_color={text_color}
                fontSize={fontSize}
                onClick={handleButtonClick}

            />
            <Modal isOpen={isModalOpen} onClose={handleModalClose} handleSubmit={handleSave} htmlCode={htmlCode}
                cssCode={cssCode}>
                <h3>Customize Button</h3>
                <div className='d-flex justify-content-center'>
                    <div className='mb-2'>
                        <label>
                            Button Text:
                            <input
                                type="text"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='mb-2'>
                        <label>
                            Background Color:
                            <input
                                type="color"
                                value={bg_color}
                                onChange={(e) => setbg_color(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='mb-2'>
                        <label>
                            Text Color:
                            <input
                                type="color"
                                value={text_color}
                                onChange={(e) => settext_color(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className='mb-2'>
                        <label>
                            Font Size:
                            <input
                                type="text"
                                value={fontSize}
                                onChange={(e) => setfontSize(e.target.value)}
                                placeholder="e.g., 16px"
                            />
                        </label>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ButtonCustomizer;


