import React, { useEffect, useRef, useState } from "react";
import "./blog.css";
import { useDispatch, useSelector } from "react-redux";
import { edit_blog, get_blog } from "../../redux/authslice";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import VerticalCarousel from "../../layout/component/VerticalCarousel";
import ButtonCustomizer from "../../layout/component/floatbutton";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

const BlogPage = () => {
  const dispatch = useDispatch();
  const { blogData } = useSelector((state) => state.counter);
  const navigate = useNavigate();
  const tokenn = localStorage.getItem("token");
  const planSectionRef = useRef(null);


  const handleSlide = () => {
    planSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  const posts = blogData?.blogposts;

  const token = localStorage.getItem("token");

  useEffect(() => {
    const blog_post_id = localStorage.getItem("blog_post_id");
    // const token  = localStorage.getItem("token");
    if (!blog_post_id) {
      navigate("/");
    }
    dispatch(get_blog({ dispatch, blog_post_id }));
  }, [dispatch]);

  const [selectedPost, setSelectedPost] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editableTitle, setEditableTitle] = useState("");
  const [editableContent, setEditableContent] = useState("");
  const [BlogData, setBlogData] = useState({
    business_name: "",
    business_address: "",
    business_phone: "",
    business_email: "",
    id: null,
  });

  const handleInputChange = (field, value) => {
    setBlogData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };


  const handleSaveinfo = () => {
    dispatch(edit_blog({ BlogData, dispatch }));
  }


  useEffect(() => {
    if (blogData?.business_info) {
      setBlogData({
        business_name: blogData?.business_info?.business_name || "",
        business_address: blogData?.business_info?.business_address || "",
        business_phone: blogData?.business_info?.business_phone || "",
        business_email: blogData?.business_info?.business_email || "",
        id: blogData?.business_info?.id || null,
      });
    }
  }, [blogData?.business_info]);


  function cleanContent(content) {
    return content
      ?.replace(/\*\*/g, "")
      ?.replace(/\*/g, "")
      ?.replace(/:/g, "")
      ?.replace(/###/g, "\n\n\n")
      ?.replace(/title/gi, "")
      ?.split("\n")
      ?.map((line) => line.trim())
      ?.filter((line) => line.length > 0)
      ?.join("\n");
  }

  const sliceContent = (content) => {
    const parts = content?.split('.');

    if (parts?.length > 5) {
      // Firs?t 3 parts joined back with dots, ensuring a dot at the end
      const firstPart = parts?.slice(0, 5).join('.') + '.';
      const remainingPart = parts?.slice(5).join('.').trim();
      return { firstPart, remainingPart };
    }
    return { firstPart: content, remainingPart: '' };
  };

  const { firstPart, remainingPart } = sliceContent(cleanContent(selectedPost?.content));

  const openModal = (post) => {
    setSelectedPost(post);
    setEditableTitle(post.title);
    setEditableContent(post.content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPost(null);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (editableTitle !== selectedPost.title || editableContent !== selectedPost.content) {
      setSelectedPost({ ...selectedPost, title: editableTitle, content: editableContent });
      const payload = { editableTitle, editableContent, id: selectedPost.id };
      dispatch(edit_blog({ payload, dispatch }));
      setSelectedPost(null);
    } else {
      toast.info("No changes made to the blog post.");
    }
    setIsModalOpen(false);
    setIsEditing(false);
  };

  const handleNavigate = () => {
    navigate('/', { state: { scrollToPlan: true } });
  };




  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-gradient-dark z-index-3 py-3 draggable" draggable="true">
        <div className="container d-flex justify-content-between">
          <Link className="navbar-brand" to="/dashboard">
            {blogData?.blogposts?.[0]?.title.toUpperCase()}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="pagesDropdown"
                                    role="button"
                                    aria-expanded="false"
                                >
                                    Pages <i className="fa-solid fa-angle-down" />
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="pagesDropdown">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Services
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="accountDropdown"
                                    role="button"
                                    aria-expanded="false"
                                >
                                    Account <i className="fa-solid fa-angle-down" />
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="accountDropdown">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Login
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Register
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Profile
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="blocksDropdown"
                                    role="button"
                                    aria-expanded="false"
                                >
                                    Blocks <i className="fa-solid fa-angle-down" />
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="blocksDropdown">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Headers
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Footers
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Content
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="docsDropdown"
                                    role="button"
                                    aria-expanded="false"
                                >
                                    Docs <i className="fa-solid fa-angle-down" />
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="docsDropdown">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Documentation
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            API Reference
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            FAQ
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div> */}

          <a href="#" className="btn btn-primary ms-3 contact-us">
            CONTACT US
          </a>
        </div>
      </nav>
      {/* -------------Main Section Start--------------- */}
      <section
        className="hero-section text-center text-white"
        style={{
          backgroundImage: `url(${blogData?.blogposts?.[0]?.image_url
            })`,
          backgroundSize: "cover",
          padding: "100px 0",
        }}
      >
        <span className="mask bg-gradient-dark opacity-7" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-9 text-start" style={{ position: "relative" }}>
              <h1
                className="display-2 font-weight-bolder text-white mb-4"
                style={{ fontWeight: 700, fontSize: blogData?.blogposts?.[0]?.title.length > 40 && 46 }}
              >
                A Blog for {blogData?.blogposts?.[0]?.title}
              </h1>
              <p className="lead text-white mb-5" style={{ fontWeight: 400 }}>
                {cleanContent(blogData?.blogposts?.[0]?.content)?.slice(0, 190)}...
              </p>
              <a className="btn btn-white" onClick={handleSlide} style={{ backgroundColor: "#fff", color: "#000" }}>
                Read more
              </a>
            </div>
          </div>
        </div>
      </section >
      {/* -------------Second Section Start--------------- */}
      < section className="py-5" >
        <div className="container">
          <div className="row">
            <div className="col text-center mb-4">
              <h2 className="" style={{ fontWeight: 400, color: "#344767" }}>
                Latest Posts
              </h2>
              <p style={{ color: "#67748e", fontWeight: 400 }}>
                See what people from all around the world are saying about our website.
              </p>
            </div>
          </div>
          <div className="container" style={{ maxWidth: "1200px", margin: "0 auto", paddingTop: "20px", paddingBottom: "20px" }}>
            <div className="outer-div" ref={planSectionRef}>
              {blogData?.blogposts?.map((post) => (
                <div key={post.id} className="post repeat-div" style={{}}>
                  <div className="left-90">
                    <img
                      src={post?.image_url}
                      alt={post?.title}
                      style={{ width: "100%", borderRadius: "8px", marginBottom: "10px" }}
                    />
                  </div>
                  <div className="flex-right-90">
                    <h2 className="h2-1">{post.title}</h2>
                    <p className="p-cus">{cleanContent(post?.content)?.slice(0, 700)}...</p>
                    <a
                      className="a-tag"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openModal(post);
                      }}
                    >
                      Read More
                    </a>
                  </div>
                </div>
              ))}
            </div>

            {isModalOpen && (
              <div style={modalStyle} className="popup-main">
                <div className="popup-main-1" style={modalContentStyle}>
                  <span style={closeButtonStyle} onClick={closeModal}>
                    ×
                  </span>
                  {isEditing ? (
                    <div className="edit-input">
                      <input
                        type="text"
                        value={editableTitle}
                        className="edit-inner-input"
                        onChange={(e) => setEditableTitle(e.target.value)}
                      />
                      <textarea
                        value={cleanContent(editableContent)}
                        className="edit-textarea-input"
                        onChange={(e) => setEditableContent(e.target.value)}
                      />
                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                        <button style={editButtonStyle} onClick={handleSave}>
                          Save
                        </button>
                        <button style={closeButtonStyleModal} onClick={closeModal}>
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center">
                      <h2 style={{ color: "#333" }}>{selectedPost.title}</h2>

                      {/* First part before the third dot */}
                      <p style={{
                        color: "#555", width: "100%", maxWidth: "85%", fontFamily: 'Jost',
                        fontSize: "17px"
                      }}>
                        {firstPart}
                      </p>

                      <div style={{ maxWidth: "400px", margin: "auto" }}>
                        <img
                          src={selectedPost?.image_url}
                          alt={selectedPost.title}
                          className="fixed-image"
                        />
                      </div>

                      {/* Second part after the third dot, if it exists */}
                      {remainingPart && (
                        <p style={{
                          color: "#555", width: "100%", maxWidth: "85%", fontFamily: 'Jost',
                          fontSize: "17px"
                        }}>
                          {remainingPart}
                        </p>
                      )}

                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                        <button style={editButtonStyle} onClick={handleEdit}>
                          Edit Post
                        </button>
                        <button style={closeButtonStyleModal} onClick={closeModal}>
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {tokenn !== null || undefined ? (
              <div className="floating-button-save">
                <Link to="/all-blogs" >
                  <button
                    style={{ background: "#344767" }}
                    onClick={() => {
                      localStorage.removeItem('blog_post_id');
                    }}
                  >
                    Save Blog
                  </button>
                </Link>
              </div>
            ) : (
              <div className="floating-button-Sign" >
                <button style={{ background: "#344767" }} onClick={handleNavigate}>
                  Sign Up for Account to Keep this Blog Forever
                </button>
              </div>
            )}
          </div>
        </div>
      </section >
      {/* -------------Cards Section Start---------------tgtttttttttttttttttt */}
      {/* <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col text-center mb-4">
              <h2 className="" style={{ fontWeight: 400, color: "#344767" }}>
                The Most Read
              </h2>
              <p style={{ color: "#67748e", fontWeight: 400 }}>
                See what people from all around the world are saying about our website.
              </p>
            </div>
          </div>
          <div className="container my-2">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <img src={img8} className="card-img-top" alt="Burger Image" />
                  <div className="card-body">
                    <p className="text-uppercase text-danger fw-bold mb-2">Fast Food</p>
                    <h5 className="card-title">Burger with Beef Meat</h5>
                    <p className="card-text">
                      Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons.
                    </p>
                    <div className="d-flex align-items-center">
                      <img
                        src={img7}
                        alt="Profile Picture"
                        className="rounded-retrangle me-2"
                        style={{ width: 40, height: 40, borderRadius: 5 }}
                      />
                      <div>
                        <p className="mb-0 fw-bold">Elijah Miller</p>
                        <small className="text-muted">Posted now</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <img src={img6} className="card-img-top" alt="Burger Image" />
                  <div className="card-body">
                    <p className="text-uppercase text-danger fw-bold mb-2">Fast Food</p>
                    <h5 className="card-title">Burger with Beef Meat</h5>
                    <p className="card-text">
                      Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons.
                    </p>
                    <div className="d-flex align-items-center">
                      <img
                        src={img5}
                        alt="Profile Picture"
                        className="rounded-retrangle me-2"
                        style={{ width: 40, height: 40, borderRadius: 5 }}
                      />
                      <div>
                        <p className="mb-0 fw-bold">Elijah Miller</p>
                        <small className="text-muted">Posted now</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow-sm">
                  <img src={img4} className="card-img-top" alt="Burger Image" />
                  <div className="card-body">
                    <p className="text-uppercase text-danger fw-bold mb-2">Fast Food</p>
                    <h5 className="card-title">Burger with Beef Meat</h5>
                    <p className="card-text">
                      Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons.
                    </p>
                    <div className="d-flex align-items-center">
                      <img
                        src={img3}
                        alt="Profile Picture"
                        className="rounded-retrangle me-2"
                        style={{ width: 40, height: 40, borderRadius: 5 }}
                      />
                      <div>
                        <p className="mb-0 fw-bold">Elijah Miller</p>
                        <small className="text-muted">Posted now</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* -------------bottom Section Start--------------- */}
      <section className="py-5">
        <div className="container">
          <div className="row align-items-center mt-5">
            <div className="col-md-6">
              <h2 className="h2-1">{blogData?.blogposts?.[0]?.title}</h2>
              <p style={{ color: "#67748e", fontWeight: 400 }}>
                {cleanContent(blogData?.blogposts?.[0]?.content)?.slice(0, 193)}
                <a href="#" className="text-decoration-none">
                  Read More →
                </a>
              </p>
              {/* <p className="text-muted">
                by{" "}
                <a href="#" className="text-decoration-none text-primary">
                  Sarah Perez
                </a>
                , 2 days ago
              </p> */}
            </div>
            <div className="col-md-6">
              <img src={blogData?.blogposts?.[0]?.image_url} alt="Cake Image" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>
      {
        token ? <><VerticalCarousel posts={posts}
          defaultHeading="Latest Blog Posts"
          initialFontSize={24}
          initialFontColor="#000000" />
        </> : ""
      }

      {/* -------------Subscribe Section Start--------------- */}
      <section className="footer bg-dark text-light pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <h5 className="text-uppercase">About Us</h5>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <label htmlFor="business-address" className="form-label">
                    <i className="fas fa-map-marker-alt"></i> Address
                  </label>
                  <input
                    type="text"
                    id="business-address"
                    className="form-control"
                    value={BlogData?.business_address || ""}
                    onChange={(e) => handleInputChange("business_address", e.target.value)}
                  />
                </li>
                <li className="mb-2">
                  <label htmlFor="business-phone" className="form-label">
                    <i className="fas fa-phone-alt"></i> Phone
                  </label>
                  <input
                    type="text"
                    id="business-phone"
                    className="form-control"
                    value={BlogData?.business_phone || ""}
                    onChange={(e) => handleInputChange("business_phone", e.target.value)}
                  />
                </li>
                <li className="mb-2">
                  <label htmlFor="business-email" className="form-label">
                    <i className="fas fa-envelope"></i> Email
                  </label>
                  <input
                    type="email"
                    id="business-email"
                    className="form-control"
                    value={BlogData?.business_email || ""}
                    onChange={(e) => handleInputChange("business_email", e.target.value)}
                  />
                </li>
              </ul>
              <button
                style={{ background: "#0056b3" }}
                onClick={handleSaveinfo}>
                Save
              </button>
            </div>






            <div className="col-lg-4 mb-4">

            </div>

            <div className="col-lg-4 mb-4">
              <h5 className="text-uppercase">News letter</h5>
              <p>Subscribe to our Newsletter for the latest updates.</p>
              <form>
                <div className="right-footer mb-2">
                  <i className="fa-solid fa-envelope"></i>
                  <input type="text" placeholder="Your Email" className="your-email" />
                </div>
                <button className="subscribe-btn w-100">Subscribe</button>
              </form>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col text-center">
              <p className="mb-0">&copy; 2024 Your Company. All Rights Reserved.</p>
            </div>
          </div>
        </div>
        {token ?
          <ButtonCustomizer /> : ""}
      </section>
    </>
  );
};

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "500px",
  position: "relative",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  overflowY: "auto",
  maxHeight: "80vh",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  fontSize: "24px",
  cursor: "pointer",
  color: "#333",
};

const editButtonStyle = {
  backgroundColor: "#344767",
  color: "white",
  border: "none",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};

const closeButtonStyleModal = {
  backgroundColor: "transparent",
  color: "#333",
  border: "1px solid #333",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};



export default BlogPage;
