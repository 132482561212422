import React from "react";
import logo from "./img/logo.png";

 const LandPageFooter = () => {
  return (
    <footer>
      <div className="footer-section">
        <div className="container">
          <div className="footer-wrap">
            <div className="row">
              <div className="col-md-3">
                <div className="footer-logo text-center">
                  <a href="index-2.html">
                    <img src={logo} className="img-fluid" alt="footer-logo" />
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-menu menu-1">
                  <h5>Links</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#"> Create Your Blog</a>
                    </li>
                    <li>
                      <a href="#">Create a Website Using AI</a>
                    </li>
                    <li>
                      <a href="#">Browse Blog Templates</a>
                    </li>
                    <li>
                      <a href="#">Sign Up Now for FREE!</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-menu menu-3">
                  <h5>follow Us</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        YouTube{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        Instagram
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-menu menu-2">
                  <h5>Menu</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">About us </a>
                    </li>
                    <li>
                      <a href="#">Benefits </a>
                    </li>
                    <li>
                      <a href="#">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyrights text-center">
            <p>Copyright © 2024 All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default LandPageFooter;