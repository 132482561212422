import BlogPostList from "../blogsetup/BlogPostList";
import MainBlog from "../blogsetup/MainBlog";
import FloatingButtonWithCode from "../FloatingButtonWithCode";
import Layout from "../layout";
import AddBlog from "../layout/component/modal/AddBlog";
import AdminHome from "../pages/admin/adminhome";
import Reffcode from "../pages/admin/reff/reffcode";
import Common from "../pages/auth/common";
import Forgot from "../pages/auth/forgot";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import Reset from "../pages/auth/reset";
import BlogPage from "../pages/blog-page";
import Home from "../pages/dashboard/home";
import MannualBlogPage from "../pages/dashboard/mannualBlogs";
import NotAuthorized from "../pages/errorpage";
import Landpage from "../pages/landing/landpage";
import CreateBlogSetup from "../pages/manual-blog/CreateBlogSetup";
import UserCreateBlogSetup from "../pages/manual-blog/userCreateblog";
import { CancelCard } from "../pages/paymentCard/cancelCrad";
import { SuccessCard } from "../pages/paymentCard/successCard";
import SubDomain from "../pages/subdomain/SubDomain";
import SubscriptionDetail from "../pages/subscription/subscriptionDetail";



const pagesData = [
    {
        path: '/',
        element: <Landpage />
    },
    {
        path: '/all-blogs',
        element: <Layout><Home /></Layout>
    },
    {
        path: '/manual-blogs',
        element: <MannualBlogPage />
    },
    {
        path: '/sub-domain',
        element: <Layout><SubDomain /></Layout>
    },
    {
        path: '/admin-dashboard',
        element: <Layout><AdminHome /></Layout>,
        requiresAdmin: true
    },
    {
        path: '/signin',
        element: <Common><Login /></Common>
    },
    {
        path: '/signup',
        element: <Common><Register /></Common>
    },
    {
        path: '/forgot',
        element: <Common><Forgot /></Common>
    },
    {
        path: '/reset-password',
        element: <Common><Reset /></Common>
    },
    {
        path: '/blog-page',
        element: <BlogPage />
    },
    {
        path: '/success',
        element: <SuccessCard />
    },

    {
        path: '/cancel',
        element: <CancelCard />
    },
    {
        path: '/add-blog',
        element: <Layout> <AddBlog /></Layout>
    },
    {
        path: '/create-blog',
        element: <CreateBlogSetup />
    },
    {
        path: '/admin-reff-code',
        element: <Layout><Reffcode /></Layout>,
        requiresAdmin: true
    },
    {
        path: '/subscription-detail',
        element: <Layout><SubscriptionDetail /></Layout>
    },
    {
        path: '/add-manual-blog',
        element: <Layout><UserCreateBlogSetup /></Layout>
    },
    {
        path: '/main-blog',
        element: < MainBlog />
    },
    {
        path: '/blog/:id',
        element: < BlogPostList />
    },
    {
        path: '/not-authorized',
        element: <NotAuthorized />
    }

]

export default pagesData;