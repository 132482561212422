import React, { useEffect } from 'react';
import { activeplan } from '../../redux/authslice';
import { useDispatch, useSelector } from 'react-redux';
import "./sub.css";



const SubscriptionDetail = () => {
    const dispatch = useDispatch();
    const { subscriptionDetail } = useSelector((state) => state.counter);

    useEffect(() => {
        dispatch(activeplan(dispatch));
    }, [dispatch]);

    if (!subscriptionDetail) {
        return <div>Loading...</div>;
    }

    const { expired, current_period_end, current_period_start, interval = '', amount_subtotal } = subscriptionDetail;

    const startDate = current_period_start ? new Date(current_period_start).toLocaleDateString() : '';
    const endDate = current_period_end ? new Date(current_period_end).toLocaleDateString() : '';

    return (
        <section className="dasboard-all pages mt-5">
            <div className="container-fluid">
                <h1 className="fs-4 fw-bold text-dark d-flex justify-content-center">Subscription Details</h1>

                <div className="subscription-card">
                    <div className="info-group">
                        <span>Status:</span>
                        <span className={expired ? 'expired' : 'active'}>
                            {expired ? 'Expired' : 'Active'}
                        </span>
                    </div>
                    <div className="info-group">
                        <span>Period Start:</span>
                        <span>{startDate}</span>
                    </div>
                    <div className="info-group">
                        <span>Period End:</span>
                        <span>{endDate}</span>
                    </div>
                    <div className="info-group">
                        <span>Interval:</span>
                        <span>{interval ? interval.charAt(0).toUpperCase() + interval.slice(1) : 'N/A'}</span>
                    </div>
                    <div className="info-group">
                        <span>Amount:</span>
                        <span>${amount_subtotal ? amount_subtotal.toFixed(2) : '0.00'}</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SubscriptionDetail;
