import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { forgot } from '../../redux/authslice';


const Forgot = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [forgotData, setForgotData] = useState({
        email: "",
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        const errors = {};

        if (!forgotData?.email.trim()) {
            errors.email = "Email address required";
        } else if (!/\S+@\S+\.\S+/.test(forgotData?.email)) {
            errors.email = "Email address  invalid";
        }
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForgotData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors)?.length === 0) {
            dispatch(forgot({ dispatch, navigate, forgotData, setForgotData }))
            return
        } else {
            setErrors(validationErrors);
        }
    };
    return (
        <div className="form-section align-self-center">
            <h1>Welcome!</h1>
            <h3>Recover Your Password</h3>
            <div className="clearfix" />
            <form action="#" method="GET" onSubmit={handleSubmit}>
                <div className="form-group form-box">
                    <label htmlFor="first_field" className="form-label">
                        Email address
                    </label>
                    <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="first_field"
                        placeholder="Email Address"
                        aria-label="Email Address"
                        value={forgotData?.email}
                        onChange={handleChange}
                    />
                    {errors.email && (
                        <p className="error_text">{errors.email}</p>
                    )}
                </div>
                <div className="form-group clearfix">
                    <button type="submit" className="btn-md btn-theme w-100">
                        Send Me Email
                    </button>
                </div>
            </form>
            <p>
                Already a member? <a href="/signin">Login here</a>
            </p>
            {/* <div className="social-list">
                <a href="#">
                    <i className="fa fa-facebook" />
                </a>
                <a href="#">
                    <i className="fa fa-twitter" />
                </a>
                <a href="#">
                    <i className="fa fa-google" />
                </a>
                <a href="#">
                    <i className="fa fa-linkedin" />
                </a>
                <a href="#">
                    <i className="fa fa-pinterest" />
                </a>
                <a href="#">
                    <i className="fa fa-youtube" />
                </a>
            </div> */}
        </div>

    )
}

export default Forgot