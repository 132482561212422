import React from 'react';
import '../App.css';
import { RingLoader } from 'react-spinners';


const Loader = () => {
    return (
        <div className="loading-screen">
            <RingLoader color="#002b91" height={15} width={5} />
        </div>
    );
}

export default Loader;