import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { getSuccessApi, postAiPet } from "../../redux/auth/authslice";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { getSuccessApi } from "../../redux/authslice";
import Header2 from "../../layout/component/header2";
import Footer2 from "../../layout/component/footer2";

export const SuccessCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isSuccess, setisSuccess] = useState(false);
  const bak = searchParams.get("bak");
  const successRef = useRef(null);
  successRef.current = isSuccess;

  const encodedString = bak;
  const decodedString = atob(encodedString);
  const data = decodedString;

  useEffect(() => {
    if (!successRef.current) {
      setTimeout(() => {
        dispatch(getSuccessApi({ data, navigate, dispatch }));
      }, 3000);
      setisSuccess(true);
    }
  }, []);

  return (
    <>
      <Header2 />
      <div className="my-auto">
        <div className="bg-white p-6 md:mx-auto" style={{ backgroundColor: "white", padding: "24px", margin: "0 auto" }}>
          <div className="d-flex justify-content-center">
            <svg
              viewBox="0 0 24 24"
              className="text-green-600 w-16 h-16 mx-auto my-6"
              style={{ width: "64px", height: "64px", margin: "24px auto", color: "#28a745" }}
            >
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
          </div>
          <div className="text-center">
            <h3
              className="md:text-2xl text-base text-gray-900 font-semibold text-center"
              style={{
                fontSize: "1.5rem",
                color: "#343a40",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Payment Done!
            </h3>
            <p className="text-gray-600 my-2" style={{ color: "#6c757d", margin: "8px 0" }}>
              Thank you for completing your secure online payment.
            </p>
            <p> Have a great day! </p>
            <div className="py-10 text-center" style={{ paddingTop: "40px", textAlign: "center" }}>
              <a
                href="/signin"
                className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
                style={{
                  padding: "12px 48px",
                  backgroundColor: "#6610f2",
                  color: "white",
                  fontWeight: "600",
                  textDecoration: "none",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                GO BACK
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};
