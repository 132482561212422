import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { create_blog, create_url_blog } from '../../redux/authslice';

const Generate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCurrentLayout, setShowCurrentLayout] = useState(true);
    const [url, setUrl] = useState('');
    const [blogName, setBlogName] = useState('');
    const [blogDescription, setBlogDescription] = useState('');
    const [errors, setErrors] = useState({ url: '', blogName: '', blogDescription: '' });

    const handleToggleClick = () => {
        setShowCurrentLayout(false);
        setErrors({ url: '', blogName: '', blogDescription: '' });
    };

    const handleBackClick = () => {
        setShowCurrentLayout(true);
        setErrors({ url: '', blogName: '', blogDescription: '' });
    };


    const validateForm = () => {
        const newErrors = {};
        if (showCurrentLayout) {
            if (!url) {
                newErrors.url = 'URL is required';
            }
        } else {
            if (!blogName) newErrors.blogName = 'Blog name is required';
            if (!blogDescription) newErrors.blogDescription = 'Blog description is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(create_url_blog({ url, setUrl, navigate, dispatch }))
    };
    const handleSubmit2 = (e) => {
        e.preventDefault();
        const payload = {
            blogName,
            blogDescription
        }
        if (validateForm()) {
            dispatch(create_blog({ payload, navigate, dispatch }))
        }
    };

    return (
        <div className="banner-contents header-sec-1">
            <div className="container">
                <div className="banner-details">
                    <div className="banner-info">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12 col-12 dek-vi">
                                <h1>Automate</h1>
                                <h2>Your Blog Posting with AI</h2>
                                <p>
                                    Transform your blogging experience with our AI-powered platform that automates
                                    the entire process of creating and posting unique, SEO-Optimized blog content on
                                    a regular basis.
                                </p>

                                <div className="blog-post-sec">
                                    <h5>Generate Your Free Blog Now!</h5>

                                    {showCurrentLayout ? (
                                        <div id="currentLayout" className="layout">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter URL of Your Website (Example: yoursite.com)"
                                                        value={url}
                                                        onChange={(e) => setUrl(e.target.value)}
                                                    />
                                                    {errors.url && <span className="error-text">{errors.url}</span>}
                                                </div>
                                                <button className="blog-btn" type="submit">BUILD MY FREE BLOG</button>
                                            </form>
                                            <h6>
                                                AI Will Crawl Your Website and Create Your Blog Based on The Content and Key Words Discovered on Your Website.
                                                If You Do Not Have a Website, Click &nbsp;
                                                <span id="toggleButton" style={{ cursor: "pointer" }} className="toggle-text" onClick={handleToggleClick}>
                                                    Here
                                                </span>
                                            </h6>
                                        </div>
                                    ) : (
                                        <div id="newLayout" className="layout">
                                            <form onSubmit={handleSubmit2}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Name of Blog (Example: The Dog Blog)"
                                                        value={blogName}
                                                        onChange={(e) => setBlogName(e.target.value)}
                                                    />
                                                    {errors.blogName && <span className="error-text">{errors.blogName}</span>}
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Brief Blog Description (Example: Blog about Poodles)"
                                                        value={blogDescription}
                                                        onChange={(e) => setBlogDescription(e.target.value)}
                                                    />
                                                    {errors.blogDescription && <span className="error-text">{errors.blogDescription}</span>}
                                                </div>
                                                <button className="blog-btn" type="submit">BUILD MY FREE BLOG</button>
                                            </form>
                                            <h6 className="text-center">
                                                If you have a website, AI can crawl your site and generate your blog! Click here:&nbsp;
                                                <span id="backButton" style={{ cursor: "pointer" }} className="toggle-text" onClick={handleBackClick}>
                                                    GENERATE BLOG FROM WEBSITE
                                                </span>
                                            </h6>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                {/* Additional content here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Generate;
