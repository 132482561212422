import React, { useState, useEffect } from 'react';
import entri from 'entrijs';

const SubDomain = () => {
    const [authToken, setAuthToken] = useState('');
    const [isAuthChecked, setIsAuthChecked] = useState(false);
    const [subdomain, setSubdomain] = useState('');
    const domain = 'siteblog.com';

    // Fetch Auth Token
    const fetchAuthToken = async () => {
        try {
            const response = await fetch('https://api.goentri.com/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    applicationId: 'siteblog',
                    secret: '010f7a26865c12b4a642f93dfc4df75e3575a70493d2b4bb65aee9ff49ffab76',
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const { auth_token } = await response.json();
            if (auth_token) {
                setAuthToken(auth_token);
                setIsAuthChecked(true);
                console.log('Auth Token fetched successfully:', auth_token);
            } else {
                throw new Error('Auth token missing in response');
            }
        } catch (error) {
            console.error('Failed to fetch auth token:', error);
        }
    };

    // Handle Entri Close Event
    useEffect(() => {
        const handleOnEntriClose = (event) => {
            console.log('onEntriClose event:', event.detail);
        };

        window.addEventListener('onEntriClose', handleOnEntriClose);
        return () => {
            window.removeEventListener('onEntriClose', handleOnEntriClose);
        };
    }, []);


    const checkDomain = async () => {
        try {
            const response = await fetch('https://api.goentri.com/checkdomain', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Ensure content type is specified
                    Authorization: `Bearer ${authToken}`, // Ensure token format matches expected API structure
                    applicationId: 'siteblog',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json(); // Parse response data if needed
            console.log('Domain check successful:', data);

            return data; // Optionally return data for further use
        } catch (error) {
            console.error('Failed to check domain:', error.message || error);
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!subdomain || !domain || !authToken) {
            console.error('Subdomain, domain, and auth token are required');
            return;
        }

        let SUBDOMAIN = subdomain
        let DOMAIN = domain
        // const config = {
        //     applicationId: "siteblog",
        //     applicationUrl: "http://35.154.171.148:3013/",
        //     token: authToken,
        //     dnsRecords: [
        //         {
        //             type: "CNAME",
        //             host: `${SUBDOMAIN}.${DOMAIN}`, // blog.xyz.example.com
        //             value: "cloudflare",
        //             ttl: 300,
        //         },
        //         {
        //             type: "TXT",
        //             host: { DOMAIN },
        //             value: "{SLD}-{TLD}", // example-com
        //             ttl: 300,
        //         },
        //         {
        //             type: "MX",
        //             host: { DOMAIN },
        //             value: `mailhost1.${DOMAIN}`, // mailhost1.example.com
        //             priority: 10,
        //             ttl: 300,
        //         },
        //     ],

        //     forceManualSetup: true
        // };

        const config = {
            applicationId: "siteblog",
            token: authToken,
            whoisOnly: false,
            DNSOnly: false,
            domain: "siteblog.com",
            dnsRecords: [
                {
                    type: "CNAME",
                    host: `${SUBDOMAIN}.${DOMAIN}`, // blog.xyz.example.com
                    value: "custom-proxy.leadpages.net",
                    ttl: 300,
                },
                {
                    type: "TXT",
                    host: "@",
                    value: "{SLD}-{TLD}", // example-com
                    ttl: 300,
                },
                {
                    type: "MX",
                    host: "host",
                    value: `mailhost1.${DOMAIN}`, // mailhost1.example.com
                    priority: 10,
                    ttl: 300,
                },
            ],
        };

        console.log('Submitting configuration:', JSON.stringify(config, null, 2));

        try {
            entri.showEntri(config);
        } catch (error) {
            console.error('Error invoking Entri modal:', error.message || error);
        }
    };

    return (
        <section className="dashboard-all pages">
            <div className="container-fluid">


                <div className="d-flex justify-content-center align-items-center mt-5">
                    <button
                        type="button"
                        onClick={checkDomain}
                        className="btn btn-primary mx-2"
                    >
                        hello
                    </button>
                    {!isAuthChecked ? (
                        <button
                            type="button"
                            onClick={fetchAuthToken}
                            className="btn btn-primary mx-2"
                        >
                            Fetch Auth Token
                        </button>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="subdomainInput" className="form-label">
                                    Subdomain
                                </label>
                                <input
                                    type="text"
                                    value={subdomain}
                                    onChange={(e) => setSubdomain(e.target.value)}
                                    className="form-control"
                                    id="subdomainInput"
                                    placeholder="Enter subdomain (e.g., blog)"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="domainInput" className="form-label">
                                    Domain
                                </label>
                                <input
                                    type="text"
                                    value={domain}
                                    disabled
                                    className="form-control"
                                    id="domainInput"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </section>
    );
};

export default SubDomain;
