import React from 'react'

const Footer2 = () => {
  return (
    <section>
    <div className="py-5 draggable" draggable="true">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-start">
            <h4 style={{ color: "#344767", fontWeight: 700 }}>Get Update with the Newest Blogs</h4>
            <p className="mb-0" style={{ color: "#67748e", fontWeight: 400 }}>
              The latest news, articles and resources sent to your inbox weekly.
            </p>
          </div>
          <div className="col-lg-5 ms-auto text-end mt-4 mt-lg-0">
            <div className="row">
              <div className="col-lg-8 col-md-4 col-7">
                <div className="form-group mb-0">
                  <div className="input-group111">
                    <span className="input-group-text1111">
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                    <input className="form-control-blog1212" placeholder="Your Email" type="email" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-5 text-start">
                <button className="contact-us ui-bt" style={{ border: "none" }}>
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Footer2