import React, { useEffect, useState } from "react";
import { LandHome } from "../landing/landHome";
import LandPageFooter from "../landing/landPageFooter";
import Header2 from "../../layout/component/header2";
import {
  generate_description,
  heroposter,
  manualblogpost,
} from "../../redux/authslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

const UserCreateBlogSetup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { imagelist } = useSelector((state) => state.counter);
  const { contentdata } = useSelector((state) => state.counter);

  const [blogDetails, setBlogDetails] = useState({
    title: "",
    domain: "",
    topic: "",
    keywords: "",
    colorScheme: "",
    description: "",
    image_logo: null,
    heroBanner: "",
    introParagraph: "",
    image_logo_url: "",
    advanced: {
      business_name: "",
      business_address: "",
      business_phone_number: "",
      business_email_address: "",
    },
  });

  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [gencontent, setGenContent] = useState("");
  const images = [imagelist?.image_path?.map((ele) => ele)];

  useEffect(() => {
    if (contentdata) {
      setBlogDetails((prev) => ({
        ...prev,
        description: contentdata?.length > 0 ? contentdata : "",
      }));
    }
  }, [contentdata]);
  const handleImageClick = (image) => {
    setCurrentImage(image);
    setShowModal(true);
  };

  const handleImageSelect = (image) => {
    setCurrentImage(image);
    setBlogDetails((prev) => ({
      ...prev,
      selectedImage: image,
    }));
    setBlogDetails((prev) => ({
      ...prev,
      image_logo_url: image,
    }));
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "image_logo") {
  //     const file = e.target.files[0];

  //     if (file) {
  //       if (blogDetails.logoPreview) {
  //         URL.revokeObjectURL(blogDetails.logoPreview);
  //       }

  //       const logoPreviewURL = URL.createObjectURL(file);

  //       setBlogDetails((prev) => ({
  //         ...prev,
  //         image_logo: file,
  //         logoPreview: logoPreviewURL,
  //       }));
  //     }
  //   } else {
  //     setBlogDetails((prev) => ({ ...prev, [name]: value }));
  //   }

  //   if (errors[name]) {
  //     setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image_logo" && files && files[0]) {
      const imageFile = files[0];

      const previewUrl = URL.createObjectURL(imageFile);

      setBlogDetails((prevDetails) => ({
        ...prevDetails,
        [name]: imageFile,
        previewUrl: previewUrl,
      }));
    } else {
      setBlogDetails((prev) => ({ ...prev, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleAdvancedChange = (e) => {
    const { name, value } = e.target;
    setBlogDetails((prev) => ({
      ...prev,
      advanced: { ...prev.advanced, [name]: value },
    }));

    // Remove error when typing
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateStep = () => {
    let stepErrors = {};

    if (step === 1) {
      if (!blogDetails.title) stepErrors.title = "Blog Name is required.";
      if (!blogDetails.domain) stepErrors.domain = "Subdomain is required.";
      if (!blogDetails.topic) stepErrors.topic = "Topics are required.";
    }

    if (step === 2) {
      // if (!blogDetails.colorScheme)
      //   stepErrors.colorScheme = "Color Scheme is required.";
      // if (!blogDetails.image_logo) stepErrors.image_logo = "Logo is required.";
    }

    if (step === 3) {
      // if (!blogDetails.introParagraph)
      //   stepErrors.introParagraph = "Intro Paragraph is required.";
      if (!blogDetails.keywords) stepErrors.keywords = "Keywords are required.";
    }
    if (step === 4) {
      // if (!blogDetails.introParagraph)
      //   stepErrors.introParagraph = "Intro Paragraph is required.";
      if (!blogDetails.description)
        stepErrors.description = "Description are required.";
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep()) {
      dispatch(
        manualblogpost({ dispatch, setBlogDetails, navigate, blogDetails })
      );
    }
    setBlogDetails({
      title: "",
      domain: "",
      topic: "",
      keywords: "",
      description: "",
      image_logo: null,
      heroBanner: "",
      introParagraph: "",
      image_logo_url: "",
      advanced: {
        business_name: "",
        business_address: "",
        business_phone_number: "",
        business_email_address: "",
      },
    });
    setGenContent("");
  };

  const generatelogo = () => {
    if (blogDetails && blogDetails?.keywords) {
      let data = blogDetails?.keywords;
      dispatch(heroposter({ dispatch, data }));
    }
  };
  const generatecontent = () => {
    if (gencontent) {
      dispatch(generate_description({ dispatch, gencontent }));
    }
  };

  return (
    <>
      <section className="dasboard-all pages">
        <div className="container-fluid">
          <div className="container mb-5 bg-red " style={{ marginTop: "4rem" }}>
            <h1 className="text-center">Create Your Blog</h1>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  {step === 1 && (
                    <div>
                      <h3>Step 1: Basic Information</h3>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          placeholder="Name of Blog"
                          value={blogDetails.title}
                          onChange={handleChange}
                          required
                        />
                        {errors.title && (
                          <p className="text-danger">{errors.title}</p>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="domain"
                          placeholder="Choose Blog’s Subdomain URL"
                          value={blogDetails.domain}
                          onChange={handleChange}
                          required
                        />
                        {errors.domain && (
                          <p className="text-danger">{errors.domain}</p>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="topic"
                          placeholder="General Topic(s) of Blog"
                          value={blogDetails.topic}
                          onChange={handleChange}
                          required
                        />
                        {errors.topic && (
                          <p className="text-danger">{errors.topic}</p>
                        )}
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNextStep}
                      >
                        Next
                      </button>
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <h3>
                        Step 2: Choose Color Scheme & Upload logo (optional)
                      </h3>
                      <div className="d-flex justify-content-around align-items-baseline">
                        <div className="form-group mb-4 mt-3">
                          <label htmlFor="colorScheme">
                            Choose Color Scheme
                          </label>
                          <input
                            type="color"
                            className="form-control"
                            name="colorScheme"
                            id="colorScheme"
                            value={blogDetails?.colorScheme}
                            onChange={handleChange}
                            style={{
                              width: "100px",
                              height: "40px",
                              padding: "0",
                              cursor: "pointer",
                            }} // Adjust the style for a better look
                          />
                          {errors.colorScheme && (
                            <p className="text-danger">{errors.colorScheme}</p>
                          )}
                        </div>

                        <div className="form-group">
                          <label>Upload Logo</label>
                          <input
                            type="file"
                            className="form-control-file"
                            accept="image/*"
                            onChange={handleChange}
                            name="image_logo" // Ensure this matches the expected name
                          />
                          {errors.image_logo && (
                            <p className="text-danger">{errors.image_logo}</p>
                          )}

                          {/* Preview of the uploaded image_logo */}
                          {blogDetails?.image_logo && (
                            <div className="mt-3">
                              <p>Logo Preview:</p>
                              <img
                                src={blogDetails?.previewUrl} // Use the preview URL
                                alt="Logo Preview"
                                style={{ width: "150px", height: "auto" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div className="form-group">
                    <label>Select Hero Banner</label>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() =>
                        handleChange({
                          target: {
                            name: "heroBanner",
                            value: "selectedHeroBannerUrl",
                          },
                        })
                      }
                    >
                      Choose Hero Banner
                    </button>
                  </div> */}
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handlePrevStep}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (blogDetails?.image_logo) {
                              setStep(step + 2);
                            } else {
                              handleNextStep();
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}

                  {step === 3 && (
                    <div>
                      <h3>Step 3: Keywords (for banner)</h3>
                      {/* <div className="form-group">
                    <textarea
                      className="form-control"
                      name="introParagraph"
                      rows="3"
                      placeholder="Intro Paragraph"
                      value={blogDetails.introParagraph}
                      onChange={handleChange}
                    ></textarea>
                    {errors.introParagraph && (
                      <p className="text-danger">{errors.introParagraph}</p>
                    )}
                    <button
                      type="button"
                      className="btn btn-secondary mt-2"
                      onClick={() =>
                        handleChange({
                          target: {
                            name: "introParagraph",
                            value: "Generated intro paragraph",
                          },
                        })
                      }
                    >
                      Regenerate Intro
                    </button>
                  </div> */}
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="keywords"
                          placeholder="Keywords to Target (comma-separated)"
                          value={blogDetails.keywords}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setBlogDetails((prev) => ({
                              ...prev,
                              [name]: value,
                            }));
                          }}
                          required
                        />
                        {errors.keywords && (
                          <p className="text-danger">{errors.keywords}</p>
                        )}
                        {blogDetails?.keywords?.length > 3 ? (
                          <button
                            type="button"
                            className="btn btn-secondary mt-2"
                            onClick={generatelogo}
                          >
                            Generate
                          </button>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* Image gallery */}
                      {images && images[0]?.length > 0 ? (
                        <div className="image-gallery mt-4">
                          <h4>Select an Image:</h4>
                          <div className="d-flex flex-wrap">
                            {images[0]?.map((image, index) => (
                              <div key={index} className="image-thumbnail m-2">
                                <img
                                  src={image} // image is directly a URL
                                  alt={`Thumbnail ${index}`} // Dynamic alt text for each image
                                  style={{ width: "100px", cursor: "pointer" }}
                                  onClick={() => handleImageClick(image)} // Pass the clicked image
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    checked={
                                      blogDetails.selectedImage === image
                                    } // Check if this image is selected
                                    onChange={() => handleImageSelect(image)} // Select/unselect image
                                  />
                                  <label>Select</label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Modal to show image in original size */}
                      <Modal
                        show={showModal}
                        onHide={handleModalClose}
                        centered
                      >
                        <Modal.Body className="text-center">
                          {currentImage && (
                            <img
                              src={currentImage}
                              alt="Original size"
                              style={{ maxWidth: "100%" }}
                            />
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-secondary"
                            onClick={handleModalClose}
                          >
                            Close
                          </button>
                        </Modal.Footer>
                      </Modal>
                      {currentImage ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-secondary mt-2"
                            onClick={() => setStep(step - 1)}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary mt-2"
                            onClick={() => setStep(step + 1)}
                          >
                            Next
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {step === 4 && (
                    <div>
                      <h3>Step 4: Genrate Content</h3>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add content to generate intro"
                          value={gencontent}
                          onChange={(e) => setGenContent(e.target.value)}
                          required
                        />
                        {gencontent?.length > 3 ? (
                          <button
                            type="button"
                            className="btn btn-secondary mt-2"
                            onClick={generatecontent}
                          >
                            {blogDetails?.description?.length > 10
                              ? "Regenerate"
                              : "Generate"}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      {blogDetails && blogDetails?.description ? (
                        <>
                          {" "}
                          <div className="form-group">
                            <textarea
                              type="text"
                              className="form-control"
                              name="description"
                              placeholder="Description"
                              value={blogDetails?.description}
                              onChange={handleChange}
                              style={{ height: "135px" }}
                              required
                            />
                            {errors.description && (
                              <p className="text-danger">
                                {errors?.description}
                              </p>
                            )}
                          </div>
                          <button
                            type="button"
                            className="btn btn-secondary mt-2"
                            onClick={() => setStep(step - 1)}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary mt-2"
                            onClick={() => setStep(step + 1)}
                          >
                            Next
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {step === 5 && (
                    <div>
                      <h3>Step 5: Advanced Information (Optional)</h3>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="business_name"
                          placeholder="Business Name"
                          value={blogDetails?.advanced?.business_name}
                          onChange={handleAdvancedChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="business_address"
                          placeholder="Business Address"
                          value={blogDetails?.advanced?.business_address}
                          onChange={handleAdvancedChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="business_phone_number"
                          placeholder="Business Phone Number"
                          value={blogDetails?.advanced?.business_phone_number}
                          onChange={handleAdvancedChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="business_email_address"
                          className="form-control"
                          name="business_email_address"
                          placeholder="Business Email Address"
                          value={blogDetails?.advanced?.business_email_address}
                          onChange={handleAdvancedChange}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handlePrevStep}
                      >
                        Back
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserCreateBlogSetup;
