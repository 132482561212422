import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; 
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import './land.css'; 
import './owll.css'; 

const Carousel = ({ videos }) => {
    const options = {
        items: 1,                 
        loop: true,              
        autoplay: true,           
        autoplayTimeout: 5000,    
        nav: true,                
        video: true,             
        lazyLoad: true,       
        margin: 0,               
        autoplayHoverPause: false,
        dots: true,
    };

    return (
        <div className="carousel-container futuristic-background">
            <OwlCarousel className="owl-theme futuristic-carousel" {...options}>
                {videos.map((videoSrc, index) => (
                    <div className="item futuristic-video" key={index}>
                        <video
                            id={`video-${index}`}
                            width="100%"
                            height="120vh"  
                            autoPlay
                            muted
                            loop
                            playsInline
                            preload="auto"
                            onContextMenu={(e) => e.preventDefault()} 
                        >
                            <source src={videoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    );
};

export default Carousel;
