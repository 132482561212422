import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "./reff.css";
import { useDispatch, useSelector } from 'react-redux';
import { generate_reff_code, get_reff_code, update_reff_code } from '../../../redux/authslice';

const Reffcode = () => {
    const dispatch = useDispatch();
    const { ReffData } = useSelector((state) => state.counter);
    const [show, setShow] = useState(false);
    const [discount, setDiscount] = useState('');
    const [edit, setEdit] = useState(false);
    const [updateid, setUpdateId] = useState("")

    const handleClose = () => {
        setShow(false);
        setEdit(false)
        setDiscount('');
    };

    const handleSwitchChange = (status) => {
        console.log("status", status)
        dispatch(update_reff_code({ status, setDiscount, updateid, discount, dispatch }));
    };

    const handleShow = () => setShow(true);
    const handleShowupdate = () => {
        setShow(true)
        setEdit(true)
    };

    const handleGenerateCode = (e) => {
        e.preventDefault();
        if (discount && !edit) {
            dispatch(generate_reff_code({ discount, setShow, dispatch }))
        } else {
            dispatch(update_reff_code({ setShow, setDiscount, updateid, discount, dispatch }))
            setDiscount('');
        }
    };

    useEffect(() => {
        dispatch(get_reff_code(dispatch))
    }, [])


    useEffect(() => {
        if (edit) {
            const code = ReffData[0]?.discount;
            const id = ReffData[0]?.id;
            if (code) {
                setDiscount(code)
                setUpdateId(id)
            } else {
                setDiscount('');
            }
        }
    }, [edit])


    return (
        <section className="dasboard-all pages">
            <div className="container-fluid">
                <div className='d-flex justify-content-between align-items-baseline mt-5'>
                    <h3>Create Referral Codes</h3>
                    {ReffData?.length > 0 ? <button onClick={handleShowupdate}>Update Referral Code</button> : <button onClick={handleShow}>Generate Referral Code</button>}

                </div>
                <table className="referral-table">
                    <thead>
                        <tr>
                            <th>Discount %</th>
                            <th>Code</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ReffData?.length > 0 ? (
                            ReffData?.map((code, index) => (
                                <tr key={index}>
                                    <td>{code?.discount} %</td>
                                    <td>{code?.code}</td>
                                    <td>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input custom-switch"
                                                type="checkbox"
                                                role="switch"
                                                checked={code?.status == "active" ? true : false}
                                                onChange={() => handleSwitchChange(code)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">No referral codes generated yet.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Referral Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleGenerateCode(e)} className="form-group-modal">
                        <div className="input-group">
                            <label>Discount %</label>
                            <input
                                type="number"
                                value={discount}
                                onChange={(e) => {
                                    const value = Math.min(100, Number(e.target.value));
                                    setDiscount(value);
                                }}
                                placeholder="Enter discount percentage"
                                required
                                min={0}
                                max={100}
                            />

                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
                            <button type="submit">{edit ? "Update" : "Submit"}</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </section>
    );

};

export default Reffcode;
